import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SculpturesGalleryContent from "../components/GalleryContent/SculpturesGalleryContent"

const SculpturesGallery = () => (
  <Layout>
    <SEO title="Sculptures Gallery" />
    <SculpturesGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SculpturesGallery
